<template>
  <div class="tile is-parent is-6">
    <div class="tile is-child has-background-dark tile-border">
      <DashboardPanel v-if="jobsOverDueToBeInvoiced && jobsToBeInvoiced">
        <p class="panel-heading has-text-centered has-text-white custom-panel-heading">JOBS COMPLETED NOT INVOICED </p>

        <DashboardProgressBar :is-loading="isLoading"
          :initial-color="'#F9BD44'"
          :loading-color="'#967a09'" />

        <div class="container column-border m-5 has-background-black"
          :class="{'clickable-panel': hasPermissions}"
          @click="handleQuoteRedirect(0)">
          <div class="
          columns">

            <div class="column is-two-fifths is-flex is-align-items-center">
              <div class="column is-2 is-flex is-align-items-center is-justify-content-center jobs-to-be-invoiced is-bold is-size-4">
                {{ jobsToBeInvoiced.length }}
              </div>
              <div class="pl-5">Jobs To Be Invoiced</div>
            </div>

            <div class="column is-two-fifths is-flex is-justify-content-space-between is-align-items-center">
              <div class="pr-3">Total Amount Not Invoiced</div>
              <div class="is-divider-vertical" />
            </div>
            <div class="column is-flex is-one-fifth is-align-items-center">
              <div>
                <div class="jobs-to-be-invoiced-value-text">{{ getCreditAmountList(jobsToBeInvoiced)[0] }}</div>
                <div class="jobs-to-be-invoiced-cents-text">.{{ getCreditAmountList(jobsToBeInvoiced)[1] }}</div>
              </div>
              <!--              <button class="button columnBorder has-background-black has-text-white">View</button>-->

            </div>
          </div>
        </div>

        <div class="container column-border m-5 has-background-black"
          :class="{'clickable-panel': hasPermissions}"
          @click="handleQuoteRedirect(2)">
          <div class="columns">
            <div class="column is-two-fifths is-flex is-align-items-center">
              <div class="column is-2 is-flex is-align-items-center is-justify-content-center jobs-to-be-invoiced is-bold is-size-4">
                {{ jobsNotInvoicedAfterEndDate.length }}
              </div>
              <div class="pl-5">Jobs Past Due Date</div>
            </div>
            <div class="column is-flex is-two-fifths is-justify-content-space-between is-align-items-center">
              <div class="pr-3">Yet To Be Invoiced</div>
              <div class="is-divider-vertical" />
            </div>
            <div class="column is-flex is-one-fifth is-align-items-center">
              <div>
                <div class="jobs-to-be-invoiced-value-text">{{ getCreditAmountList(jobsNotInvoicedAfterEndDate)[0] }}</div>
                <div class="jobs-to-be-invoiced-cents-text">.{{ getCreditAmountList(jobsNotInvoicedAfterEndDate)[1] }}</div>
              </div>
              <!--              <button class="button columnBorder has-background-black has-text-white">View</button>-->
            </div>
          </div>
        </div>

      </DashboardPanel>
    </div>
  </div>
</template>

<script>
import DashboardPanel from '@/views/dashboard/DashboardPanel.vue'
import DashboardService from '@/views/dashboard/DashboardService'
import DashboardProgressBar from '@/views/dashboard/DashboardProgressBar.vue'
import JobStageTypes from '@/enums/JobStageTypes'
import { DateTime } from 'luxon'

export default {
  name: 'DashboardUnInvoicedJobs',
  components: { DashboardProgressBar, DashboardPanel },
  props: {
    defaultDataObj: null
  },
  data() {
    return {
      isLoading: false,
      jobsToBeInvoiced: [],
      jobsOverDueToBeInvoiced: [],
      jobsNotInvoicedAfterEndDate: []
    }
  },
  computed: {
    filterKey() {
      if (!this.$userInfo) return ''
      return `${this.$userInfo.sessionId}|Quote|filter`
    },
    JobStageTypes() {
      return JobStageTypes
    },
    DateTime() {
      return DateTime
    },
    hasPermissions() {
      const jcniPermissions = this.$user?.permissions?.find((perm) => perm.name === 'JCNI')
      if (jcniPermissions?.hasAccess) return true
      return false
    }
  },
  created() {
    // this.constantlyUpdateData()
    this.getDashboardJcni()
  },
  beforeDestroy() {
    if (this.timeOutId) clearTimeout(this.timeOutId)
  },

  methods: {
    async getDashboardJcni() {
      this.isLoading = true
      let promises = []
      promises.splice(promises.length, 1, this.getJobsCompletedNotInvoiced())
      promises.splice(promises.length, 1, this.getJobsPassDueDateNotInvoiced())
      await Promise.all(promises)
      this.isLoading = false
    },
    async getJobsCompletedNotInvoiced() {
      this.jobsToBeInvoiced = await DashboardService.getDashboardJcni(0)
    },
    async getJobsOverDueToBeInvoiced() {
      this.jobsOverDueToBeInvoiced = await DashboardService.getDashboardJcni(1)
    },
    async getJobsPassDueDateNotInvoiced() {
      this.jobsNotInvoicedAfterEndDate = await DashboardService.getDashboardJcni(2)
    },
    // constantlyUpdateData() {
    //   this.getDashboardJcni()
    //   // we want to update the data every 30 seconds
    //   this.timeOutId = setTimeout(this.constantlyUpdateData, 1000 * 30)
    // },

    getSum(jobs) {
      if (jobs.length === 0) return 0
      return jobs.map((job) => job.totalNotInvoiced).reduce((prev, next) => prev + next)
    },
    getCreditAmountList(jobs) {
      return this.$filters.formatCurrency(this.getSum(jobs), this.$userInfo.locale).split('.')
    },
    handleQuoteRedirect(jcniCode) {
      if (!this.hasPermissions) return
      this.$router.push('/jcni?jcni=' + jcniCode)
    }
  }
}
</script>

<style scoped>
.jobs-to-be-invoiced {
  background: #f9bf00;
  border-radius: 6px 0px 0px 6px;
  height: 100%;
}

.jobs-to-be-invoiced-value-text {
  color: #f9bf00;
  font-size: x-large;
  font-weight: bold;
  display: inline-block;
}

.jobs-to-be-invoiced-cents-text {
  color: #f9bf00;
  font-size: medium;
  display: inline-block;
}

.column-border {
  border: 1px solid #f9bf00;
  border-radius: 8px;
}

.jobs-overdue-to-be-invoiced {
  background: #d92550;
  border-radius: 6px 0px 0px 6px;
  height: 100%;
}

.overdue-jobs-border {
  border: 1px solid #d92550;
  border-radius: 8px;
}

.overdue-jobs-value-text {
  color: #d92550;
  font-size: x-large;
  font-weight: bold;
  display: inline-block;
}

.overdue-jobs-cents-text {
  color: #d92550;
  font-size: medium;
  display: inline-block;
}
.tile-border {
  border-radius: 20px !important;
}

.custom-panel-heading {
  border-radius: 20px 20px 0 0 !important;
  background-color: #495057 !important;
}

.clickable-panel {
  cursor: pointer;
  opacity: 0.9;
}

.clickable-panel:hover {
  opacity: 1;
  background: linear-gradient(to left, #222 50%, hsl(0, 0%, 8%) 50%);
  background-size: 200% 120%;
  background-position: right;
  transition: background-position 0.3s ease-out;
}
</style>