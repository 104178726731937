export default {
  AddNew: 'addnew',
  Print: 'print',
  Delete: 'delete',
  Save: 'save',
  Cancel: 'cancel',
  ToggleSide: 'toggleside',
  Lock: 'lock',
  PriceParts: 'priceparts'
}
