<template>
  <div class="dashboard has-background-black has-text-white h-100">
    <div style="margin: 10px;">

      <div class="tile is-ancestor"
        :class="{'h-100': isUninvoicedJobsAndChartDisabled}">
        <div class="tile is-6 h-100">
          <DashboardStatues :is-uninvoiced-jobs-and-chart-disabled="isUninvoicedJobsAndChartDisabled"
            :data-obj="defaultDataObj" />
        </div>

        <div class="tile is-3 h-100"
          v-if="$company && $company.info && $company.info.countryCode !== 'MY'">
          <div class="tile is-parent is-vertical pb-0 pl-0">
            <DashboardPnet :is-uninvoiced-jobs-and-chart-disabled="isUninvoicedJobsAndChartDisabled" />
            <DashboardOrm :is-uninvoiced-jobs-and-chart-disabled="isUninvoicedJobsAndChartDisabled" />
          </div>
        </div>
        <DashboardIncomingMessages :is-uninvoiced-jobs-and-chart-disabled="isUninvoicedJobsAndChartDisabled" />
      </div>

      <div class="tile is-ancestor"
        v-if="!isUninvoicedJobsAndChartDisabled">
        <DashboardUnInvoicedJobs :default-data-obj="defaultDataObj" />
        <DashboardTotalJobs />
      </div>
    </div>

  </div>
</template>

<script>
import DashboardIncomingMessages from './DashboardIncomingMessages'
import DashboardUnInvoicedJobs from '@/views/dashboard/DashboardUnInvoicedJobs.vue'
import DashboardTotalJobs from '@/views/dashboard/DashboardTotalJobs.vue'
import DashboardStatues from '@/views/dashboard/DashboardStatues.vue'
import DashboardPnet from '@/views/dashboard/DashboardPnet.vue'
import DashboardOrm from '@/views/dashboard/DashboardOrm.vue'
import DashboardService from '@/views/dashboard/DashboardService'
import Guid from '@/components/Guid'
import { Columns } from '@/views/quote/columns'

export default {
  name: 'Dashboard',
  components: {
    DashboardOrm,
    DashboardPnet,
    DashboardStatues,
    DashboardTotalJobs,
    DashboardUnInvoicedJobs,
    DashboardIncomingMessages
  },
  data() {
    return {
      incomingMessages: [],
      unInvoicedJobs: null,
      reportData: null,
      isLoading: false,
      isUninvoicedJobsAndChartDisabled: true,
      quotes: null,
      defaultDataObj: {
        dateFrom: '',
        dateTo: '',
        insurerId: Guid.empty(),
        jobStage: 2,
        active: 1,
        invoiceStatus: 'B',
        authorisedStatus: 'B',
        quoteType: 'Q',
        sortColumn: Columns[0].name,
        sortOrder: Columns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 12,
        search: '',
        claimsTypeKey: ''
      }
    }
  },
  computed: {},
  created() {
    this.getData()
  },
  mounted() {
    this.$store.watch(
      (state) => {
        return state.appnav
      },
      {
        deep: true
      }
    )
  },
  methods: {
    async getData() {
      this.isLoading = true
      const data = await DashboardService.disableUninvoicedJobsAndJobChartStatus()
      // we set isUninvoicedJobsAndChartDisabled to true when component gets created
      // we update the value when we get the response from the server
      this.isUninvoicedJobsAndChartDisabled = data
      this.isLoading = false
    }
  }
}
</script>
<style lang="scss" scoped>
.dashboard {
  min-height: calc(100vh - 52px);
  overflow-y: hidden !important;
}

#app > div.app-content {
  overflow-y: auto !important;
}

.h-100 {
  min-height: 100% !important;
  max-height: 100% !important;
}
</style>

