/* Vue */
import Vue from 'vue'
import router from './router'
import store from './store'
/* App sass */
import './assets/style/app.scss'
/* App component */
import App from './App'
/* Auth plugin */
import Auth from './auth'
/* Notification plugin */
import Notification from './notification'
import LzString from './lz-string'
import Guid from './guid'

// Vue.use(VueResource)
Vue.config.productionTip = false

Vue.use(Auth)

Vue.use(Notification)
Vue.use(LzString)
Vue.use(Guid)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
